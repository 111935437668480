import Utility from "../../../shared/utility.js";
import EncryptUtility from "./encrypt-utility.js";
import AddFavourite from "./addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      restockRepairValid: false,
      unitIdentifier: "",
      primaryLabelSize: "",
      primaryPrinterType: "",
      pass: 1,
      selectedRestockRepair: "",
      restockRepairItems: [],
      search: "",
      totalRecords: 0,
      showDataTable: false,
      projectDet: [],
      showProject: false,
      showType: false,
      selectedType: "",
      binItems: [],
      selectedBin: "",
      count: 0,
      searchUnit: false,
      projectSelected: false,
      typeSelected: false,
      selectedProject: "",
      showPrimary: true,
      headersRestockRepair: [
        {
          text: "Bin",
          align: "start",
          value: "Bin",
          class: "primary customwhite--text",
          sortable: false,
        },
        {
          text: "Component Part#",
          value: "Component Part#",
          class: "primary customwhite--text",
        },
        {
          text: "Pick Ticket",
          value: "Pick Ticket",
          class: "primary customwhite--text",
        },
        {
          text: "Warehouse",
          value: "Warehouse",
          class: "primary customwhite--text",
        },
        {
          text: "Serialno",
          value: "Serialno",
          class: "primary customwhite--text",
        },
        {
          text: "Issue Date",
          value: "Issue Date",
          class: "primary customwhite--text",
        },
        { text: "Tech", value: "Tech", class: "primary customwhite--text" },
        {
          text: "Description",
          value: "Description",
          class: "primary customwhite--text",
        },
      ],
      max36Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 36 || "Max Length of 36 character"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.restockRepairValid = false;
      this.unitIdentifier = "";
      this.pass = 1;
      this.selectedRestockRepair = "";
      this.restockRepairItems = [];
      this.search = "";
      this.totalRecords = 0;
      this.showDataTable = false;
      this.projectDet = [];
      this.showProject = false;
      this.showType = false;
      this.selectedType = "";
      this.selectedBin = "";
      this.count = 0;
      this.binItems = [];
      this.searchUnit = false;
      this.projectSelected = false;
      this.typeSelected = false;
      this.selectedProject = "";
      this.$refs.RestockRepairForm.resetValidation();
    },
    //Search for Restock Repair issues
    searchRestockRepair() {
      if (!this.$refs.RestockRepairForm.validate()) return false;
      this.pass = 1;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        repair_unit: this.unitIdentifier,
        UserId: parseInt(this.userId),
        cnt: 0,
        zid: 0,
        bin_id: 0,
        main_id_in: 0,
      };
      this.axios
        .post("/ut/restock_repair_issues", searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.projectDet = responsedata.ProjList;
            if (this.projectDet.length == 1) {
              this.selectedProject = this.projectDet[0].proj_key;
              this.projectChange();
            }
            this.showProject = true;
            this.searchUnit = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Project Change
    projectChange() {
      this.showType = true;
      this.projectSelected = true;
      if (this.projectDet.length == 1) {
        this.selectedType = this.projectDet[0].main_id;
        this.typeChange();
      }
    },
    //Type Change
    typeChange() {
      this.pass = 2;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchUnitIdentifier = {
        pass: parseInt(this.pass),
        repair_unit: this.unitIdentifier,
        UserId: parseInt(this.userId),
        cnt: 0,
        zid: 0,
        bin_id: 0,
        main_id_in: this.selectedType,
      };
      this.axios
        .post("/ut/restock_repair_issues", searchUnitIdentifier)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.count = responsedata.Count;
            this.totalRecords = responsedata.Grid.length;
            this.binItems = responsedata.BinList;
            const newArr = responsedata.Grid.map((obj) => {
              return {
                ...obj,
                "Issue Date": Utility.convertESTToLocal(obj["Issue Date"]),
              };
            });
            this.restockRepairItems = newArr;
            this.showDataTable = true;
            this.typeSelected = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Bin Change
    binChange(item) {
      let binDetails = this.binItems.filter((obj) => obj.zid === item.zid);
      this.pass = 3;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let binUpdate = {
        pass: parseInt(this.pass),
        repair_unit: this.unitIdentifier,
        UserId: parseInt(this.userId),
        cnt: this.count,
        zid: item.zid,
        bin_id: binDetails[0].bin_id,
        main_id_in: this.selectedType,
      };
      this.axios
        .post("/ut/restock_repair_issues", binUpdate)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = [];
            this.binItems = [];
            this.restockRepairItems = [];
            this.totalRecords = 0;
            this.count = 0;
            responsedata = JSON.parse(response.data.body.message);
            this.count = responsedata.Count;
            this.totalRecords = responsedata.Grid.length;
            this.binItems = responsedata.BinList;
            this.restockRepairItems = responsedata.Grid;
            this.showDataTable = true;
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
